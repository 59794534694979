import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import isJWT from "validator/lib/isJWT";

import { makeStyles } from "@mui/styles";
import {
  Container,
  Stack,
  Paper,
  Avatar,
  Button,
  TextField,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import LoadingPaper from "../tools/loadingPaper";
import { goHomePage } from "../tools/browserHelpers";

import UserAPI from "../api/userApi";
import { useSelector, useDispatch } from "react-redux";
import { setStatusMsg, setUser } from "../data/settings";

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.settings.user);
  const text = useSelector((s) => s.settings.texts.signin);

  const [xPass, setPass] = useState("");
  const [xEmail, setEmail] = useState("");

  const [isReset, setReset] = useState(false);
  const [busy, setBusy] = useState(false);

  const hCloseMsg = () => {
    dispatch(setStatusMsg([0, ""]));
  };

  const hLogin = () => {
    hCloseMsg();
    if (busy) return;

    if (isEmail(xEmail) === false) {
      dispatch(setStatusMsg([2, text.emailReq]));
      return;
    }

    if (xPass.length < 1) {
      dispatch(setStatusMsg([2, text.passReq]));
      return;
    }

    setBusy(true);

    const data = {
      email: xEmail,
      pwd: xPass,
    };

    UserAPI.login(
      data,
      (resp) => {
        setBusy(false);
        if (resp.token !== undefined) {
          if (isJWT(resp.token)) {
            dispatch(setStatusMsg([1, text.welcomeMsg]));
            dispatch(setUser(resp));
            goHomePage();
            return;
          }
        }

        dispatch(setStatusMsg([2, text.failedUser]));
      },
      (x) => {
        setBusy(false);
        if (x.e !== undefined) {
          dispatch(setStatusMsg([2, text.failedUser]));
          return;
        }
        dispatch(setStatusMsg([3, "server"]));
      }
    );
  };

  const hReset = () => {
    hCloseMsg();
    if (busy) return;

    if (isEmail(xEmail) === false) {
      dispatch(setStatusMsg([2, text.emailReq]));
      return;
    }

    setBusy(true);

    UserAPI.pwdReset(
      { em: xEmail },
      (m) => {
        setBusy(false);
        dispatch(setStatusMsg([1, text.passSent]));
      },
      (x) => {
        setBusy(false);
        if (x.e !== undefined) {
          dispatch(setStatusMsg([3, "gen"]));
          return;
        }
        dispatch(setStatusMsg([3, "server"]));
      }
    );
  };

  const showLogin = () => {
    return (
      <>
        <TextField
          title=""
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          type="password"
          name="password"
          autoComplete="current-password"
          value={xPass}
          onChange={(event) => {
            setPass(event.target.value);
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              ev.preventDefault();
              hLogin();
            }
          }}
          label={text.pass}
        />
        <Button
          onClick={() => {
            setReset(true);
          }}
        >
          {text.forget}
        </Button>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            hLogin();
          }}
        >
          {text.login}
        </Button>
      </>
    );
  };

  const showReset = () => {
    return (
      <>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            hReset();
          }}
          className={classes.btn}
        >
          {text.reset}
        </Button>
        <Button
          onClick={() => {
            setReset(false);
          }}
        >
          {text.login}
        </Button>
      </>
    );
  };

  if (user) {
    goHomePage();
    return <></>;
  }

  if (text) {
    return (
      <Container maxWidth="sm" sx={{ padding: "25px 5px" }}>
        <Paper className={classes.paper}>
          <Stack
            spacing={2}
            alignItems={"center"}
            width={"100%"}
            padding="15px 0px"
          >
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <TextField
              title=""
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={xEmail}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              label={text.emailaddress}
            />
            {isReset ? showReset() : showLogin()}

            <Link href="/signup" variant="body2">
              {text.regnote}
            </Link>
          </Stack>
        </Paper>

        <LoadingPaper open={busy} />
      </Container>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "block",
    position: "relative",
    padding: theme.spacing(2),
  },
  avatar: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default LoginForm;

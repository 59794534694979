import React from "react";
import { Provider } from "react-redux";
import store from "../coms/data/store";

import Seo from "../coms/global/Seo";
import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import LoginForm from "../coms/user/LoginForm";

const SigninPage = () => {
  return (
    <>
      <Seo title="Log into Schoode" pathname="/signin" />
      <Provider store={store}>
        <MainFrame>
          <Layout>
            <LoginForm />
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default SigninPage;
